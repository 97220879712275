import React, { useState, useEffect, useContext, useRef } from 'react';
import './PlayerInterface.css';
import { SocketContext } from '../SocketContext';
import { useLocation, useNavigate } from 'react-router-dom';

const generateBingoCard = () => {
    const columns = [
        { name: 'B', numbers: Array.from({ length: 15 }, (_, i) => i + 1) },
        { name: 'I', numbers: Array.from({ length: 15 }, (_, i) => i + 16) },
        { name: 'N', numbers: Array.from({ length: 15 }, (_, i) => i + 31) },
        { name: 'G', numbers: Array.from({ length: 15 }, (_, i) => i + 46) },
        { name: 'O', numbers: Array.from({ length: 15 }, (_, i) => i + 61) },
    ];

    return columns.map(col => {
        const selectedNumbers = [];
        while (selectedNumbers.length < 5) {
            const randomIndex = Math.floor(Math.random() * col.numbers.length);
            selectedNumbers.push(col.numbers.splice(randomIndex, 1)[0]);
        }
        return selectedNumbers;
    }).map((col, idx) => idx === 2 ? [...col.slice(0, 2), "FREE", ...col.slice(3)] : col);
};

const getBingoLetter = number => ['B', 'I', 'N', 'G', 'O'][Math.floor((number - 1) / 15)];

const PlayerInterface = () => {
    const socket = useContext(SocketContext);
    const navigate = useNavigate();
    const [card] = useState(generateBingoCard());
    const [markedNumbers, setMarkedNumbers] = useState([]);
    const [drawnNumbers, setDrawnNumbers] = useState([]);
    const [currentDraw, setCurrentDraw] = useState(null);
    const [lastFive, setLastFive] = useState([]);
    const [name, setName] = useState('');
    const [gameName, setGameName] = useState('Default Game');
    const [gameNumber, setGameNumber] = useState(1);
    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');
    const [joinedPlayers, setJoinedPlayers] = useState([]);

    const chatMessagesRef = useRef(null);
    const location = useLocation();
    const playerName = new URLSearchParams(location.search).get('name');

    useEffect(() => {
        if (!socket) return;
        if (playerName) {
            setName(playerName);
            socket.emit('newPlayer', playerName);
            socket.emit('requestState');
        }

        socket.on('currentState', state => {
            setDrawnNumbers(state.drawnNumbers);
            setCurrentDraw(state.drawnNumbers[state.drawnNumbers.length - 1] || null);
            setLastFive(state.drawnNumbers.slice(-5));
            setGameName(state.gameName);
            setGameNumber(state.gameNumber);
            setJoinedPlayers(state.players || []);
        });

        socket.on('numberDrawn', number => {
            setDrawnNumbers(prev => [...prev, number]);
            setCurrentDraw(number);
            setLastFive(prev => [...prev, number].slice(-5));
        });

        socket.on('newGame', newGameNumber => {
            setDrawnNumbers([]);
            setCurrentDraw(null);
            setLastFive([]);
            setGameNumber(newGameNumber);
        });

        socket.on('updateGameName', setGameName);
        socket.on('updateGameNumber', setGameNumber);
        socket.on('clearMarkedNumbers', () => setMarkedNumbers([]));
        socket.on('chatMessage', msg => setChatMessages(prev => [...prev, msg]));
        socket.on('playerJoined', newPlayer => {
            if (!joinedPlayers.includes(newPlayer)) {
                setJoinedPlayers(prev => [...prev, newPlayer]);
                setChatMessages(prev => [...prev, { name: 'System', text: `${newPlayer} has joined the game` }]);
            }
        });

        const handleBeforeUnload = event => {
            const confirmationMessage = 'You will lose your Bingo card and marked numbers. Are you sure?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            socket.off('currentState');
            socket.off('numberDrawn');
            socket.off('newGame');
            socket.off('updateGameName');
            socket.off('updateGameNumber');
            socket.off('clearMarkedNumbers');
            socket.off('chatMessage');
            socket.off('playerJoined');
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [socket, playerName, joinedPlayers]);

    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleMarkNumber = number => {
        if (number === "FREE" || (!markedNumbers.includes(number) && drawnNumbers.includes(number))) {
            setMarkedNumbers(prev => [...prev, number]);
        } else if (markedNumbers.includes(number)) {
            setMarkedNumbers(prev => prev.filter(num => num !== number));
        }
    };

    const handleChatSubmit = e => {
        e.preventDefault();
        if (chatInput.trim()) {
            socket.emit('chatMessage', { name, text: chatInput });
            setChatInput('');
        }
    };

    return (
        <div className="player-interface">
            <button onClick={() => navigate('/')} className="main-screen-button">Main Screen</button>
            {name ? (
                <>
                    <h1>Welcome to EBM Bingo, {name}!</h1>
                    <div className="game-container">
                        <div className="info-boxes-container">
                            <div className="info-box">
                                <h2>Current Draw:</h2>
                                <div className="data current-number">{currentDraw ? `${getBingoLetter(currentDraw)} ${currentDraw}` : ''}</div>
                            </div>
                            <div className="info-box game-info">
                                <div className="game-number-container">

                                    <div className="game-number">Game # {gameNumber}</div>

                                    <div className="bingo-type"><h2>Bingo Type:</h2> {gameName}</div>
                                </div>
                                <div className="last-five-numbers">
                                    <h2>Last 5 Numbers Called</h2>
                                    <div>{lastFive.map(num => `${getBingoLetter(num)} ${num}`).join(', ')}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bingo-card">
                            <div className="header">
                                <div>B</div><div>I</div><div>N</div><div>G</div><div>O</div>
                            </div>
                            {card.map((col, colIndex) => (
                                <div key={colIndex} className="column">
                                    {col.map((number, rowIndex) => (
                                        <div
                                            key={rowIndex}
                                            className={`cell ${markedNumbers.includes(number) ? 'marked' : ''}`}
                                            onClick={() => handleMarkNumber(number)}
                                        >
                                            {number}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        {/* New iframe player added below the bingo card but above the chat */}
                        <div className="video-player-container">
                            <h2>Live Stream</h2>
                            <div className="responsive-video">
                                <iframe
                                    src="https://player.restream.io/?token=74c4444cb0dc4ee38f3d58359f8aa455&vwrs=1"
                                    allow="autoplay"
                                    allowFullScreen
                                    frameBorder="0"
                                    title="Live Stream"
                                ></iframe>
                            </div>
                        </div>

                        <div className="chat-container">
                            <div className="chat-box" ref={chatMessagesRef}>
                                {chatMessages.map((msg, index) => (
                                    <div key={index} className="chat-message">
                                        <strong>{msg.name}: </strong>{msg.text}
                                    </div>
                                ))}
                            </div>
                            <form onSubmit={handleChatSubmit} className="chat-form">
                                <input
                                    type="text"
                                    value={chatInput}
                                    onChange={e => setChatInput(e.target.value)}
                                    placeholder="Type your message..."
                                    className="chat-input"
                                />
                                <button type="submit" className="chat-send-button">Send</button>
                            </form>
                        </div>
                    </div>
                </>
            ) : <h2>Please enter your name in the URL parameter.</h2>}
        </div>
    );
};

export default PlayerInterface;
