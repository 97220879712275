import React, { useState, useEffect, useContext, useRef } from 'react';
import './AdminDashboard.css';
import { SocketContext } from '../SocketContext';

const getBingoLetter = (number) => {
    if (number >= 1 && number <= 15) return 'B';
    if (number >= 16 && number <= 30) return 'I';
    if (number >= 31 && number <= 45) return 'N';
    if (number >= 46 && number <= 60) return 'G';
    if (number >= 61 && number <= 75) return 'O';
    return '';
};

const AdminDashboard = () => {
    const socket = useContext(SocketContext);
    const [drawnNumbers, setDrawnNumbers] = useState([]);
    const [lastDrawnNumber, setLastDrawnNumber] = useState(null);
    const [gameNumber, setGameNumber] = useState(1);
    const [gameType, setGameType] = useState('Default Game');
    const [drawCount, setDrawCount] = useState(0);
    const [playerCount, setPlayerCount] = useState(0);
    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');
    const [isAdminJoined, setIsAdminJoined] = useState(false);
    const chatMessagesEndRef = useRef(null);
    const joinedPlayers = useRef(new Set());

    useEffect(() => {
        if (!socket) return;

        if (!isAdminJoined) {
            socket.emit('newPlayer', 'Admin');
            setIsAdminJoined(true);
        }

        socket.emit('requestState');

        socket.on('currentState', (state) => {
            setDrawnNumbers(state.drawnNumbers);
            setLastDrawnNumber(state.drawnNumbers[state.drawnNumbers.length - 1] || null);
            setGameNumber(state.gameNumber);
            setGameType(state.gameName);
            setDrawCount(state.drawnNumbers.length);
            setPlayerCount(state.playerCount);
        });

        socket.on('playerCountUpdate', (count) => {
            setPlayerCount(count);
        });

        socket.on('chatMessage', (msg) => {
            setChatMessages((prevMessages) => [...prevMessages, msg]);
        });

        socket.on('playerJoined', (newPlayer) => {
            if (!joinedPlayers.current.has(newPlayer)) {
                joinedPlayers.current.add(newPlayer);
                setChatMessages((prevMessages) => [...prevMessages, { name: 'System', text: `${newPlayer} has joined the game` }]);
            }
        });

        return () => {
            socket.off('currentState');
            socket.off('playerCountUpdate');
            socket.off('chatMessage');
            socket.off('playerJoined');
        };
    }, [socket, isAdminJoined]);

    const drawNumber = () => {
        const remainingNumbers = Array.from({ length: 75 }, (_, i) => i + 1).filter(num => !drawnNumbers.includes(num));
        if (remainingNumbers.length === 0) return; // Prevent drawing if no numbers are left
        const randomIndex = Math.floor(Math.random() * remainingNumbers.length);
        const number = remainingNumbers[randomIndex];
        setDrawnNumbers((prev) => [...prev, number]);
        setLastDrawnNumber(number);
        setDrawCount((prev) => prev + 1);
        socket.emit('numberDrawn', number);
    };

    const handleGameTypeChange = (e) => {
        const newGameType = e.target.value;
        setGameType(newGameType);
        socket.emit('gameNameChange', newGameType);
    };

    const handleNextGame = () => {
        const newGameNumber = gameNumber + 1;
        setGameNumber(newGameNumber);
        setDrawnNumbers([]);
        setLastDrawnNumber(null);
        setDrawCount(0);
        socket.emit('newGame', newGameNumber);
        socket.emit('clearMarkedNumbers');
    };

    const handleResetGame = () => {
        if (window.confirm("Are you sure you want to reset the game? This will clear all drawn numbers and marked numbers on players' cards.")) {
            setDrawnNumbers([]);
            setLastDrawnNumber(null);
            setDrawCount(0);
            socket.emit('resetGame');
        }
    };

    const handleChatSend = () => {
        if (chatInput.trim()) {
            const message = { name: 'Admin', text: chatInput };
            socket.emit('chatMessage', message);
            setChatInput('');
        }
    };

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <div className="control-panel">
                <div className="draw-number-container">
                    <button onClick={drawNumber} className="draw-button">Draw Number</button>
                    <div className="draw-count">Draw Count: {drawCount}</div>
                    <div className="player-count">Players in Game: {playerCount}</div>
                </div>
                <div className="game-type">
                    <label>Bingo Type: </label>
                    <input
                        type="text"
                        value={gameType}
                        onChange={handleGameTypeChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleGameTypeChange(e)}
                    />
                </div>
                <div className="next-game-container">
                    <div className="game-number">Game # {gameNumber}</div>
                    <button onClick={handleNextGame} className="next-game-button">Next Game</button>
                    <button onClick={handleResetGame} className="reset-game-button">Reset Game</button>
                </div>
            </div>
            <div className="info-container">
                <div className="info-row">
                    <div className="info-box small">
                        <h2>Current Draw:</h2>
                        <div className="data current-number">{lastDrawnNumber ? `${getBingoLetter(lastDrawnNumber)} ${lastDrawnNumber}` : ''}</div>
                    </div>
                    <div className="info-box small">
                        <h2>Last 5 Numbers Called:</h2>
                        <div className="data last-five-numbers">
                            {drawnNumbers.slice(-5).reverse().map(num => `${getBingoLetter(num)} ${num}`).join(', ')}
                        </div>
                    </div>
                </div>
                <div className="info-box full-width">
                    <h2>Drawn Numbers:</h2>
                    <div className="numbers-list">
                        {drawnNumbers.map(num => `${getBingoLetter(num)} ${num}`).join(', ')}
                    </div>
                </div>
            </div>
            <div className="chat-container">
                <h2>Chat</h2>
                <div className="chat-messages">
                    {chatMessages.map((msg, index) => (
                        <div key={index} className="chat-message"><strong>{msg.name}: </strong>{msg.text}</div>
                    ))}
                    <div ref={chatMessagesEndRef} />
                </div>
                <div className="chat-input-container">
                    <input
                        type="text"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleChatSend(e)}
                        placeholder="Type your message..."
                    />
                    <button onClick={handleChatSend}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
