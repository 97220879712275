import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        const adminKey = process.env.REACT_APP_ADMIN_KEY;

        if (password === adminKey) {
            navigate('/admin/dashboard', { replace: true });  // Direct navigation to the dashboard
        } else {
            alert('Incorrect password');
        }
    };

    return (
        <div className="admin-login">
            <h1>Admin Login</h1>
            <form onSubmit={handleLogin}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter admin password"
                    required
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
