import React, { createContext, useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';

// Updated Heroku URL
const ENDPOINT = "https://powerful-ridge-39638-953d7629939b.herokuapp.com";

// Create the SocketContext
const SocketContext = createContext();

// SocketProvider component to wrap your app
const SocketProvider = ({ children, playerName }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Create new socket connection
        const newSocket = socketIOClient(ENDPOINT, {
            transports: ['websocket'], // Ensure WebSocket transport is used
        });

        // When connected to the server
        newSocket.on('connect', () => {
            console.log('Connected to server');
            // Request the current game state when connected
            newSocket.emit('requestState');

            // If playerName is provided, emit it to the server
            if (playerName) {
                newSocket.emit('newPlayer', playerName);
            }
        });

        // Handle disconnection
        newSocket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        // Handle reconnection attempts
        newSocket.on('reconnect', (attemptNumber) => {
            console.log(`Reconnected on attempt: ${attemptNumber}`);
        });

        // Handle connection errors
        newSocket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        // Set the socket state
        setSocket(newSocket);

        // Cleanup function to remove all listeners and close the socket
        return () => {
            if (newSocket) {
                newSocket.off('connect');
                newSocket.off('disconnect');
                newSocket.off('reconnect');
                newSocket.off('connect_error');
                newSocket.close();
            }
        };
    }, [playerName]); // Depend on playerName to reinitialize if it changes

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export { SocketContext, SocketProvider };
